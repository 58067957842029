import emoji from 'react-easy-emoji';

export const greeting = {
	username: 'Артем Суворин',
	title: 'Добрый день! Я Артем Суворин',
	subTitle: "",
	role: "Руководитель отдела Инфраструктуры",
	resumeLink: "",
	// IMPORTANT
	// If you don't want to show this, change view to false. DO NOT DELETE!
	view: true
};

export const socialMediaLinks = {
	// IMPORTANT
	// if you don't have, change value to blank or false!! DO NOT DELETE!
	github: 'https://vk.com/artem_cska',
	linkedin: 'https://t.me/+79629328009',
	facebook: 'https://wa.me/79629328009',
	email: 'artem@suvorin.pro',
	twitter: "",
	instagram: "",
	medium: '',
	stackoverflow: ''
};

export const skills = {
	title: "Почему я лучше?⚡",
	subTitle: "Мои компетенции:",
	describeSkills: [
		"✔ Вырос из базовых позиций в IT и вижу задачи со всех сторон. Знаю процессы изнутри.",
		"✔ Работаю в крупных федеральных/международных компаниях с большой разветвленной инфраструктурой и имею опыт ее модернизации и построения.",
		"✔ Имею опыт написания регламентов и построения рабочих процессов.",
		"✔ Мой опыт позволяет мне планировать и реализовывать проекты разной сложности как с помощью усилий своей команды, так и с помощью компаний партнеров.",
		"✔ Знаю и применяю на практике современные методологии управления, например SCRUM.",
		"✔ Мне обычно удается быстро и хорошо адаптировать и встраивать новых сотрудников в команду.",
		"✔ Мне неоднократно приходилось решать технические вопросы с заграничными коллегами, как письменно, так и в режиме конференции. Неплохо знаю английский язык.",
		"✔ Не боюсь говорить правду и решать реальные проблемы.",
		"✔ Имею опыт работы с бюджетом в разных экономических ситуациях.",
		"✔ Более 8 лет в IT.",
		"✔ Мой потенциал еще не раскрыт до конца, я могу лучше, вместе с вами!",
	],
	// IMPORTANT
	// You need to find className for font-awesome icon
	// Go to https://fontawesome.com/ and search icon
	lists: [
		{fontAwesome: "fas fa-crown", text: "Лидерство", proficiency: 100},
		{fontAwesome: "fas fa-clipboard-check", text: "Контроль", proficiency: 100},
		{fontAwesome: "fas fa-user", text: "Подбор", proficiency: 100},
		{fontAwesome: "fas fa-flask", text: "Опыт", proficiency: 100},
		{fontAwesome: "fas fa-rocket", text: "Проекты", proficiency: 100},
	],
	view: true
};

export const experience = {
	title: "Опыт работы 🧐",
	lists: [
		{
			date: "08.2020 - н.в.",
			company: "Maytoni",
			role: "Руководитель отдела Инфраструктуры",
			url: "https://maytoni.ru",
		},
		{
			date: "02.2018 - 08.2020",
			company: "INCITY",
			role: "Ведущий системный администратор",
			url: "https://incity.ru/incity/",
		},
		{
			date: "09.2016 - 02.2018",
			company: "MosLine",
			role: "Дежурный инженер",
			url: "https://mosline.ru/",
		},
		{
			date: "12.2014 - 09.2016",
			company: "Акадо Телеком",
			role: "Эксперт технической поддержки",
			url: "https://www.akado.ru/",
		},
	],
	view: true
}

export const openSourceProjects = {
	title: "Open Source Projects 💻",
	view: false
}

export const projects = {
	title: "И еще проекты 💻",
	subTitle: "Не менее крупные и важные проекты:",
	lists: [
		{
			title: "",
			desc: "",
			url: ""
		},
	],
	view: true
}

export const achievements = {
	title: "То, чем я горжусь! 🏆",
	subTitle: "Часть из самых крупных и красочных проектов!",
	lists: [
		{
			img: require("./assets/artplay.png"),
			alt: "",
			title: "Шоурум Maytoni ArtPlay",
			desc: "Несмотря на свою миниатюрность, данный проект оказался крайне интересным и сложным. Любые коммуникации, которые идут из серверной в зал, должны были быть полностью скрыты. Это означало, что необходимо заранее продумать каждый метр и, главное, донести это до каждого из более чем 10 подрядчиков, работавших на объекте(ремонтная бригада, мебельщики, электрики и т.д.). Буквально каждый кабель с любовью проводился через место, подготовленное и спроектированное специально для него. Добавляли ''перчинки'' сроки - всего чуть более месяца. С точки зрения IT, было реализовано достаточно много систем: от стандартных видеонаблюдения и wi-fi до системы умного дома и выдвижного проектора.",
			url: "https://disk.yandex.ru/d/pRDcKp0Olj9qvQ"
		},
		{
			img: require("./assets/07.jpg"),
			alt: "",
			title: "Новый офис INCITY в Башне-2000",
			desc: "Новый офис для INCITY был настоящим вызовом. Дело даже не в самом переезде, а в многочисленных задачах вокруг него. В частности, в новом доме для компании было решено отказаться от собственной серверной, вся инфраструктура с того момента должна была переехать в ЦОД. Конечно, работа компании и всей ее федеральной сети не останавливалась при этом. Ключом к успеху явилось правильное планирование. В самом же офисе были внедрены все необходимые бизнесу сервисы. Кроме основных, из нововведений для INCITY на тот момент: биометрия, централизованное аудио, новая система удаленного доступа.",
			url: "https://disk.yandex.ru/d/amlXw__yrgis_Q"
		},
		{
			img: require("./assets/08.png"),
			alt: "",
			title: "Флагманский шоурум Maytoni на Мосфильмовской",
			desc: "Еще один шоурум для Maytoni и самый долгий проект в моей жизни. Около 9 месяцев подряд все вместе мы создавали этого гиганта. Для меня данная работа важна тем, что именно в ней я познакомился со многими новыми системами. В частности, запомнилась реализация идеи с составными ТВ панели и их контроллером, датчиками охранной системы, системами для ведения прямых эфиров в интернет и профессиональным звуком. Большой упор в этом пространстве был сделан на беспроводное подключение клиентов к сети, вкупе с бетонными стенами это ставило большую задачу, которую, как и все остальные, мы успешно решили.",
			url: "https://disk.yandex.ru/d/DKNH0nj6NMQBqQ"
		},
	],
	view: true
};

export const blogs = {
	title: "Обо мне 📰",
	subTitle: "",
	lists: [
		{
			title: "Общая информация",
			desc: "Я родился 13 Июня 1992 года в Москве. Мое главное качество - это целеустремленность. Цели, которые я себе ставлю, всегда достигаются. Стремлюсь к профессиональному росту. Я не теряюсь в нестандартных ситуациях, например, во время крупной аварии на сети или во время переговоров. Обычно я схватываю все на лету. Качество, над которым я хотел бы поработать, - излишняя прямолинейность. Иногда не стоит ''выкладывать все карты на стол'' сразу.",
			url: ""
		},
		{
			title: "Образование",
			desc: "Я закончил класс с физико-математическим уклоном. Участвовал и побеждал в олимпиадах по разным наукам(как гуманитарным, так и техническим) на уровне школы, района и округа. Закончил Российский Государственный Социальный Университет в степени бакалавра по специальности Инженерная защита окружающей среды. Также имею сертификаты от вендора Microsoft.",
			url: ""
		},
				{
			title: "Остальное",
			desc: "Мое хобби - это авто и мотоспорт. Я время от времени посещаю гран-при Формулы 1. Люблю игровые виды спорта такие как футбол и волейбол. В студенческую пору играл за сборную университета на волейбольной площадке. Обожаю историю и никогда не против пообщаться и поспорить на разные темы. В целом мне комфортно в любом коллективе, я всегда нахожу общий язык с коллегами.",
			url: ""
		},
	],
	view: true
}

export const contactInfo = {
	title: "Свяжитесь со мной!",
	subTitle: "Я на связи по любому из четырех контактов ниже:",
	introduce: emoji(""),
	view: true
}
